import { SPOTIFY_CONFIG } from './spotifyConfig';

let accessToken = null;
let tokenExpirationTime = null;

const getAccessToken = async () => {
  // Token geçerli ise mevcut token'ı kullan
  if (accessToken && tokenExpirationTime && Date.now() < tokenExpirationTime) {
    return accessToken;
  }

  try {
    const response = await fetch(SPOTIFY_CONFIG.AUTH_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + btoa(SPOTIFY_CONFIG.CLIENT_ID + ':' + SPOTIFY_CONFIG.CLIENT_SECRET),
      },
      body: 'grant_type=client_credentials',
    });

    const data = await response.json();
    accessToken = data.access_token;
    tokenExpirationTime = Date.now() + (data.expires_in * 1000);
    return accessToken;
  } catch (error) {
    console.error('Spotify token alınamadı:', error);
    throw error;
  }
};

export const searchSpotify = async (query) => {
  try {
    const token = await getAccessToken();
    const response = await fetch(
      `https://api.spotify.com/v1/search?q=${encodeURIComponent(query)}&type=track&limit=10`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    return data.tracks.items.map(track => ({
      id: track.id,
      name: track.name,
      artists: track.artists,
      album: {
        name: track.album.name,
        images: track.album.images
      },
      preview_url: track.preview_url
    }));
  } catch (error) {
    console.error('Şarkı araması başarısız:', error);
    return [];
  }
}; 