import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaInstagram, FaUtensils, FaMusic } from 'react-icons/fa';
import { onValue, ref, off } from 'firebase/database';
import { db } from '../firebase/config';
import './BottomBar.css';

const BottomBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMusicEnabled, setIsMusicEnabled] = useState(false);

  useEffect(() => {
    const musicRef = ref(db, 'Cafe/System/music');
    
    const unsubscribe = onValue(musicRef, (snapshot) => {
      if (snapshot.exists()) {
        setIsMusicEnabled(snapshot.val().enabled);
      } else {
        setIsMusicEnabled(false);
      }
    });

    return () => off(musicRef);
  }, []);

  const handleMenuClick = () => {
    navigate('/menu');
  };

  // Menü butonunun active durumunu kontrol et
  const isMenuActive = location.pathname === '/menu';

  return (
    <div className="bottom-bar">
      <button 
        className={`bottom-bar-button ${isMenuActive ? 'active' : ''}`}
        onClick={handleMenuClick}
      >
        <FaUtensils className="bottom-bar-icon" />
        <span>Menü</span>
      </button>
      
      {isMusicEnabled && (
        <button 
          className={`bottom-bar-button ${location.pathname === '/song-request' ? 'active' : ''}`}
          onClick={() => navigate('/song-request')}
        >
          <FaMusic className="bottom-bar-icon" />
          <span>Şarkı İstek</span>
        </button>
      )}
      
      <button 
        className="bottom-bar-button"
        onClick={() => window.open('https://www.instagram.com/leraskozan/', '_blank')}
      >
        <FaInstagram className="bottom-bar-icon" />
        <span>Instagram</span>
      </button>
    </div>
  );
};

export default BottomBar; 