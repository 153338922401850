import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { searchSpotify, getSongRequests, addSongRequest, voteSong } from '../firebase/firebaseService';
import { ref, onValue, off } from 'firebase/database';
import { db } from '../firebase/config';
import Preloader from '../components/Preloader';
import './SongRequest.css';

const SongRequest = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [songRequests, setSongRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [votedSongs, setVotedSongs] = useState(() => {
    const saved = localStorage.getItem('votedSongs');
    return saved ? JSON.parse(saved) : {};
  });
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  // Realtime güncelleme için listener
  useEffect(() => {
    const requestsRef = ref(db, 'Cafe/SongRequests');
    const unsubscribe = onValue(requestsRef, (snapshot) => {
      if (snapshot.exists()) {
        const requests = [];
        snapshot.forEach((childSnapshot) => {
          requests.push({
            id: childSnapshot.key,
            ...childSnapshot.val()
          });
        });
        // Beğeni sayısına göre sırala
        requests.sort((a, b) => (b.likes || 0) - (a.likes || 0));
        setSongRequests(requests);
      } else {
        setSongRequests([]);
      }
    });

    // Cleanup function
    return () => off(requestsRef);
  }, []);

  // Debounce fonksiyonu
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  // Arama işlemi
  const handleSearch = debounce(async (term) => {
    if (term.length < 2) {
      setSearchResults([]);
      return;
    }
    setLoading(true);
    try {
      const results = await searchSpotify(term);
      setSearchResults(results);
    } catch (error) {
      console.error('Arama hatası:', error);
    }
    setLoading(false);
  }, 500);

  // Input değiştiğinde
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    handleSearch(value);
  };

  const handleRequestSong = async (song) => {
    try {
      // Şarkı bilgilerini düzenli formatta hazırla
      const songData = {
        spotifyId: song.id,
        name: song.name,
        artist: song.artists[0].name,
        albumCover: song.album.images[0].url,
        preview_url: song.preview_url,
        likes: 1, // Başlangıç olarak 1 like
        dislikes: 0,
        timestamp: Date.now()
      };

      // Şarkıyı ekle ve ID'sini al
      const songId = await addSongRequest(songData);
      
      // Şarkıyı ekleyen kişinin oyunu kaydet
      if (songId) {
        const newVotedSongs = { 
          ...votedSongs, 
          [songId]: {
            type: 'like',
            timestamp: Date.now()
          }
        };
        setVotedSongs(newVotedSongs);
        localStorage.setItem('votedSongs', JSON.stringify(newVotedSongs));
      }

      // Arama sonuçlarını ve arama terimini temizle
      setSearchTerm('');
      setSearchResults([]);
    } catch (error) {
      console.error('Şarkı isteği hatası:', error);
    }
  };

  const handleVote = async (songId, voteType) => {
    try {
      // Daha önce oy kullanılmış mı kontrol et
      const vote = votedSongs[songId];
      if (vote) {
        const now = Date.now();
        // 12 saat = 43200000 milisaniye
        if (now - vote.timestamp < 43200000) {
          return; // 12 saat dolmadan oy kullanılamaz
        }
      }

      await voteSong(songId, voteType);
      
      // Oy bilgisini güncelle
      const newVotedSongs = { 
        ...votedSongs, 
        [songId]: {
          type: voteType,
          timestamp: Date.now()
        }
      };
      setVotedSongs(newVotedSongs);
      localStorage.setItem('votedSongs', JSON.stringify(newVotedSongs));
    } catch (error) {
      console.error('Oy verme hatası:', error);
    }
  };

  if (pageLoading) {
    return <Preloader />;
  }

  return (
    <div className="song-request-page">
      <div className="search-section">
        <div className="search-input">
          <input
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Şarkı veya sanatçı ara..."
          />
        </div>

        {loading ? (
          <div className="loading">Aranıyor...</div>
        ) : (
          <div className="search-results">
            {searchResults.map(song => (
              <div key={song.id} className="song-result">
                <img src={song.album.images[2].url} alt={song.name} />
                <div className="song-info">
                  <h3>{song.name}</h3>
                  <p>{song.artists[0].name}</p>
                  {song.preview_url && (
                    <audio controls>
                      <source src={song.preview_url} type="audio/mpeg" />
                    </audio>
                  )}
                </div>
                <button onClick={() => handleRequestSong(song)}>
                  İstek Yap
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="requests-section">
        <h2>Şarkı İstekleri</h2>
        {songRequests
          .sort((a, b) => b.likes - a.likes)
          .map(request => (
            <div key={request.id} className="song-request">
              <img src={request.albumCover} alt={request.name} />
              <div className="request-info">
                <h3>{request.name}</h3>
                <p>{request.artist}</p>
              </div>
              <div className="vote-buttons">
                <button
                  onClick={() => handleVote(request.id, 'like')}
                  className={`vote-btn like ${votedSongs[request.id]?.type === 'like' ? 'voted' : ''}`}
                  disabled={votedSongs[request.id] && Date.now() - votedSongs[request.id].timestamp < 43200000}
                >
                  👍 {request.likes || 0}
                </button>
                <button
                  onClick={() => handleVote(request.id, 'dislike')}
                  className={`vote-btn dislike ${votedSongs[request.id]?.type === 'dislike' ? 'voted' : ''}`}
                  disabled={votedSongs[request.id] && Date.now() - votedSongs[request.id].timestamp < 43200000}
                >
                  👎 {request.dislikes || 0}
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SongRequest; 