import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaMusic } from 'react-icons/fa';
import { onValue, ref, off } from 'firebase/database';
import { db } from '../firebase/config';
import './Home.css';

const Home = () => {
  const [isMusicEnabled, setIsMusicEnabled] = useState(false);

  useEffect(() => {
    // Ana sayfada bottom bar'ı gizle
    const bottomBar = document.querySelector('.bottom-bar');
    if (bottomBar) {
      bottomBar.style.display = 'none';
    }

    // Müzik sistemi durumunu kontrol et
    const musicRef = ref(db, 'Cafe/System/music');
    const unsubscribe = onValue(musicRef, (snapshot) => {
      if (snapshot.exists()) {
        setIsMusicEnabled(snapshot.val().enabled);
      }
    });

    // Component unmount olduğunda
    return () => {
      // Bottom bar'ı tekrar göster
      if (bottomBar) {
        bottomBar.style.display = 'flex';
      }
      // Listener'ı temizle
      off(musicRef);
    };
  }, []);

  return (
    <div className="home-container">
      <div className="home-content">
        <img 
          src="https://firebasestorage.googleapis.com/v0/b/lib-18147.appspot.com/o/images%2FLeras-logo.png?alt=media&token=57f65473-2f3a-45cb-b207-d00cb4ed574f"
          alt="Leras Logo"
          className="home-logo"
        />
        <div className="home-buttons">
          <Link to="/menu" className="home-button menu-button">
            MENÜ
          </Link>
          {isMusicEnabled && (
            <Link to="/song-request" className="home-button song-request-button">
              <FaMusic className="button-icon" />
              <span>ŞARKI İSTEK</span>
            </Link>
          )}
          <a 
            href="https://www.instagram.com/leraskozan/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="home-button instagram-button"
          >
            <FaInstagram className="instagram-icon" />
            <span>INSTAGRAM</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home; 