import { ref, get, push, set, update } from 'firebase/database';
import { db } from './config';
import { searchSpotify as spotifySearch } from '../spotify/spotifyService';

// Tüm menü öğelerini getir
export const getAllMenuItems = async () => {
  try {
    const menuRef = ref(db, 'Cafe/Products');
    const snapshot = await get(menuRef);
    if (snapshot.exists()) {
      const items = [];
      snapshot.forEach((childSnapshot) => {
        const item = childSnapshot.val();
        items.push({
          id: childSnapshot.key,
          productName: item.productName,
          productPrice: item.productPrice,
          productCategory: item.productCategory,
          orderNumber: item.orderNumber
        });
      });
      return items.sort((a, b) => a.orderNumber - b.orderNumber);
    }
    return [];
  } catch (error) {
    console.error("Menü öğeleri getirilirken hata oluştu:", error);
    throw error;
  }
};

// Tüm kategorileri getir
export const getAllCategories = async () => {
  try {
    const categoriesRef = ref(db, 'Cafe/Categories');
    const snapshot = await get(categoriesRef);
    if (snapshot.exists()) {
      const categories = [];
      snapshot.forEach((childSnapshot) => {
        const category = childSnapshot.val();
        categories.push({
          id: childSnapshot.key,
          name: category.categoryName,
          image: category.categoryBanner,
          sortOrder: category.sortOrder
        });
      });
      // Kategorileri sortOrder'a göre sırala
      return categories.sort((a, b) => a.sortOrder - b.sortOrder);
    }
    return [];
  } catch (error) {
    console.error("Kategoriler getirilirken hata oluştu:", error);
    throw error;
  }
};

// Spotify API ile şarkı arama
export const searchSpotify = async (searchTerm) => {
  return await spotifySearch(searchTerm);
};

// Şarkı isteklerini getir
export const getSongRequests = async () => {
  try {
    const requestsRef = ref(db, 'Cafe/SongRequests');
    const snapshot = await get(requestsRef);
    if (snapshot.exists()) {
      const requests = [];
      snapshot.forEach((childSnapshot) => {
        requests.push({
          id: childSnapshot.key,
          ...childSnapshot.val()
        });
      });
      return requests;
    }
    return [];
  } catch (error) {
    console.error("Şarkı istekleri getirilirken hata:", error);
    throw error;
  }
};

// Yeni şarkı isteği ekle
export const addSongRequest = async (songData) => {
  try {
    const songRef = ref(db, 'Cafe/SongRequests');
    const newSongRef = push(songRef);
    await set(newSongRef, songData);
    return newSongRef.key; // Yeni eklenen şarkının ID'sini döndür
  } catch (error) {
    console.error('Şarkı eklenirken hata:', error);
    throw error;
  }
};

// Şarkıya oy ver
export const voteSong = async (songId, voteType) => {
  try {
    const songRef = ref(db, `Cafe/SongRequests/${songId}`);
    const snapshot = await get(songRef);
    if (snapshot.exists()) {
      const song = snapshot.val();
      const updates = {};
      if (voteType === 'like') {
        updates.likes = song.likes + 1;
      } else {
        updates.dislikes = song.dislikes + 1;
      }
      await update(songRef, updates);
    }
  } catch (error) {
    console.error("Oy verilirken hata:", error);
    throw error;
  }
}; 